import React from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import axios from "axios";
import { useEffect, useState } from "react";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import FlatButton from "../components/Reusables/FlatButton";
import styled from "styled-components";
import { Link } from "gatsby";
import UniversityStudents from "../components/Students/UniversityStudents";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../components/Students/EligibilityAndBenefits";
import Accordion from "../components/Reusables/Accordion";
import StatisticBox from "../components/Home/StatisticBlock";
import events1 from "../images/pageImages/Students/Events.png";
import hero from "../images/pageImages/Students/Hero.jpg";
import Testimonial1 from "../images/pageImages/Students/Testimonial1.jpg";
import Testimonial2 from "../images/pageImages/Students/Testimonial2.jpg";
import rise from "../images/pageImages/Students/Rise.jpg";
import aim from "../images/pageImages/Students/Aim.jpg";
import springboard from "../images/pageImages/Students/Springboard.jpg";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .apply-now {
    padding: 3% 0;
    background-color: white;

    :hover {
      background-color: #e43d5a;
      span {
        color: white;
      }
    }
  }

  .flat-apply-button {
    color: black;
    text-decoration: none;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-upreach-button {
    margin-top: 5px;
    color: black;
    text-decoration: none;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
    border-bottom: 4px solid black;
  }

  .link-upreach {
    padding: 3% 0;
    background-color: white;
    margin-top: 20px;
    :hover {
      background-color: #f2b842;
      span {
        color: white;
      }

      .flat-upreach-button {
        border-bottom: 4px solid #eee;
      }
    }
  }

  .flat-upreach-button-text {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
    display: flex;
    justify-content: center;
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .section-divider {
    margin-top: 50px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .grew-up {
    padding: 3% 0;
    background-color: #5db9db;
    height: 160px;

    @media (max-width: 600px) {
      height: unset;
      padding: 5% 8%;
    }
  }

  .onhover-display {
    color: white;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    display: none;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .grew-up:hover {
    padding-top: 2%;
  }

  .grew-up:hover .onhover-display {
    display: block;
  }

  .grew-up:hover .flat-button {
    display: none;

    @media (max-width: 600px) {
      display: unset;
    }
  }

  .onhover-links {
    color: white;
  }
  .onhover-links:hover {
    color: white;
  }
`;

const statistic = [
  {
    numbers: 55,
    description:
      "Associates who attended exclusive work experience opportunities through upReach received subsequent offers for internships or graduate roles at the firm.",
    color: "rgb(70, 79, 208)",
  },
  {
    numbers: 92,
    description:
      "of Associates who attended a Professional Experience Week at a partner employer and applied for an internship/graduate role the following year were successful.",
    color: "rgb(48, 69, 99)",
    suffix: "%",
  },
  {
    numbers: 167,
    description:
      "Professional Experience Week places & internships offered exclusively to upReach Associates through our partner employers. ",
    color: "rgb(93, 185, 219)",
  },
  {
    numbers: 74,
    description:
      "upReach Associates have secured offers from 74 of the Times Top 100 Graduate Employers.",
    color: "#FF8600",
  },
  {
    numbers: 99,
    description:
      "of  Associates who had a Mock Interview said it helped them feel more prepared for employer interviews.",
    color: "rgb(70, 79, 208)",
    suffix: "%",
  },
  {
    numbers: 99,
    description:
      "of upReach Associates would recommend upReach support to a friend.",
    color: "rgb(48, 69, 99)",
    suffix: "%",
  },
  {
    numbers: 4000,
    description:
      "upReach Associates who were in highly skilled employment 15 months after graduation had a median average salary of £28,000, £4,000 more than the national average.",
    color: "rgb(93, 185, 219)",
    prefix: "£",
  },
];

const programs = [
  {
    title: "RISE",
    description:
      "Rise is typically a three year programme of personalised career support designed to help undergraduates explore their career options and enhance their employability to achieve their career ambitions with application support for any employer in any career sector.  This programme is sponsored by our Partner Universities or Foundations, which determines eligibility.",
    image: `${rise}`,
    cta: [
      { title: "Partner Universities" },
      { title: "John Lyon's Charity - NW London Postcode" },
      { title: "Argent Foundation - King's Cross or Birmingham Postcode" },
      { title: "A&O - Smart Start participant" },
      { title: "Sponsors" },
    ],
  },
  {
    title: "AIM",
    description:
      "Aim is a targeted professional development and application support programme, available to undergraduates from all universities looking to make applications to our partner employers.",
    image: `${aim}`,
  },
  {
    title: "Sector Springboard",
    description:
      "A Sector Springboard is a sector targeted professional development and application support programme, designed to support undergraduates who are interested in joining a particular sector or industry.  Current Sector Springboards include: Banking & Finance, Investment and Savings, Law and Technology. ",
    image: `${springboard}`,
    cta: [
      { title: "Law", href: "/law-springboard" },
      { title: "Investment", href: "/investment-springboard" },
      { title: "Technology", href: "/investment-springboard" },
      { title: "Banking and Finance", href: "/investment-springboard" },
    ],
  },
];

const accordion = [
  {
    title: "Insight Days",
    description:
      "Hosted by our partner employers, these events offer an insight into their culture, work, opportunities and application processes through presentations and networking.",
  },
  {
    title: "Careers Academies",
    description:
      "Events featuring sector insights and skills development sessions delivered by the upReach team and partner employers. Sessions are designed to be both practical and interactive, engaging Associates in sectors and skill areas.",
  },
  {
    title: "Professional Experience Weeks",
    description:
      "Work experience opportunities, delivered by our partner employers exclusively for upReach Associates. Successful Professional Experience Week applicants attend a 2-5 day programme run by the employer, which tends to include workshops, shadowing opportunities and networking events.",
  },
  {
    title: "Skills Workshops",
    description:
      "Skills sessions based around the ten graduate employability skills evaluated in the Graduate Employability Framework. These are delivered at our partner universities, primarily for Rise Associates, but are open to all Associates at that university regardless of programme.",
  },
  {
    title: "Video Forum",
    description:
      "Online mini skills workshops - the opportunity to learn about skills, sectors and employers in an interactive group setting that enables peer support and discussion.",
  },
];

const carousel = [
  {
    description:
      "Being an Associate helps you set out your career goals and builds your confidence to apply.",
    image: `${Testimonial1}`,
    author: "- Alfie, Senior Associate at KPMG",
  },
  {
    description:
      "I couldn’t have got my offer at Deloitte without upReach and my mentor at the firm.",
    image: `${Testimonial2}`,
    author: "- Kim, Tax Associate at Deloitte",
  },
];

const accordionHeading = {
  heading: "We have over 650 events a year. ",
  include: "Our events include:",
};

const heading = { title: "Our Programmes" };

const heroImage = { hero: `${hero}` };

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Students />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/students-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};

const Students = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [hashPath, setHashPath] = useState(null);
  const { data: pageData } = useQuery("studentData", fetchData);
  const { data: heroImage } = useQuery("studentImages", fetchHeroImage);
  const { data: stats } = useQuery("studentStats", fetchStats);
  useEffect(() => {
    setHashPath(document.location.hash);
  }, []);

  if (hashPath === "#programme" || hashPath === "#eligibility") {
    setTimeout(() => {
      document
        .querySelector(hashPath)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }, 900);
  }
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
    if (stats !== undefined) {
      setStatsData(stats.data);
    }
  }, [pageData, heroImage, stats]);
  const handleApply = () => {
    ReactGA.event({
      category: "Students Page",
      action: "Apply Now - Click",
    });
  };
  const handleButton = () => {
    ReactGA.event({
      category: "Students Page",
      action: "Grew Up In Southwark - Click",
    });
  };

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "StudentsPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {newData && newData.CtaButton && (
          <div className="apply-now">
            <Link className="flat-btn-link" to={newData.CtaButton.link}>
              <span className="flat-apply-button" onClick={handleApply}>
                {newData.CtaButton.title}
              </span>
            </Link>
          </div>
        )}
        {newData && (
          <div className="link-upreach">
            <span className="flat-upreach-button-text">
              {newData.CtaButtonText}
            </span>
            {newData.CtaButton2 && (
              <Link
                className="flat-btn-link"
                to={newData.CtaButton2.link}
                target="_blank"
              >
                <span className="flat-upreach-button">
                  {newData.CtaButton2.title}
                </span>
              </Link>
            )}
          </div>
        )}
        {newData &&
          newData.showUniversitySection &&
          newData.UniversitySection.length > 0 && (
            <UniversityStudents data={newData.UniversitySection} />
          )}
        {/* <SectionDivider /> */}
        {newData &&
          newData.showStatistics &&
          statsData &&
          statsData.length > 0 &&
          statsData.map(
            item =>
              item.slug.toLowerCase() === "Students Page".toLowerCase() &&
              item.StudentStatistics.length > 0 && (
                <StatisticBox statistic={item.StudentStatistics} />
              )
          )}
        <div id="programme" style={{ marginTop: "100px" }}>
          {newData && newData.showHoverEffectBlock && (
            <HoverEffectBlocks
              programs={newData.HoverEffectBlock}
              heading={newData}
            />
          )}
        </div>
        {/* {newData && (
          <div className="grew-up flat-btn-link">
            <span className="flat-button">GREW UP IN SOUTHWARK? </span>
            <div className="onhover-display">
              <p className="mb-3">
                Do you have a Southwark home address (when at College/Sixth
                Form)?
              </p>
              <div onClick={handleButton}>
                <Link target="_blank" className="onhover-links" to="/southwark">
                  Check out this Programme designed for you.{" "}
                </Link>
              </div>
            </div>
          </div>
        )} */}

        {newData && newData.showCarousel && newData.Carousel.length > 0 && (
          <Testimonial carousel={newData.Carousel} />
        )}
        <div id="eligibility" />
        {newData &&
          newData.showEligibility &&
          newData.Eligibility.length > 0 && (
            <EligibilityAndBenefits data={newData.Eligibility} />
          )}
        {newData && newData.showAccordion && (
          <div className="container-fluid p-0">
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};
